import { BasketManager } from 'services/basket-services/basket/basketManager.service';
import { watch } from 'vue';
import { useUserStore } from 'store/user.store';
import { UserHelper } from 'utils/userHelper.util';

export async function useRefreshBasketData(forceUpdate?: boolean) {
  const basketManager = new BasketManager();
  const watchSubscription = new WatchSubscription();
  const userStore = useUserStore();

  watchSubscription.add(
    watch(
      () => userStore.isUserLoggedIn,
      (isUserLoggedIn) => {
        if (isUserLoggedIn && UserHelper.isClient && (!basketManager.basketId.value || forceUpdate)) {
          basketManager.refreshBasketData();
        }
      },
      { immediate: true },
    ),
  );

  useSSRUnsubscribeWatch(watchSubscription);
}
